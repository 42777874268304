import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import HeroSection from "./DetailPage/HeroSection";
import FormSection from "./DetailPage/FormSection";
import ImageSection from "./DetailPage/ImageSection";
import MobileApplication from "./DetailPage/MobileApplication";
import Notify from "./DetailPage/Notify";
import Testimonial from "./DetailPage/Testimonial";
const App = () => {
  return (
    <>
      <Box>
        <HeroSection />
      </Box>

      <Box>
        <FormSection />
      </Box>

      <Box>
        <ImageSection />
      </Box>

      <Box>
        <MobileApplication />
      </Box>

      <Box>
        <Notify />
      </Box>

      <Box>
        <Testimonial />
      </Box>

    </>
  );
};

export default App;