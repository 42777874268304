import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import HeroSection from "./DetailPage/HeroSection";
import ServiceSector from "./DetailPage/ServiceSector";
import StopSolution from "./DetailPage/StopSolution";
import Grow from "./DetailPage/Grow";
import WhyChoose from "./DetailPage/WhyChoose";
import GrowSecond from "./DetailPage/GrowSecond";
import ImageSection from "../WebDevelopment/DetailPage/ImageSection";
import Testimonial from "../AppDevelopment/DetailPage/Testimonial";
const Home = () => {
  return (
    <>
      <Box>
          <HeroSection />
      </Box>

      <Box>
        <ServiceSector />
      </Box>

      <Box>
        <StopSolution />
      </Box>
      
      <Box>
        <Grow />
      </Box>

      <Box>
        <WhyChoose />
      </Box>

      <Box>
        <GrowSecond />
      </Box>

      <Box>
        <ImageSection />
      </Box>

      <Box>
        <Testimonial />
      </Box>

    </>
  );
};

export default Home;