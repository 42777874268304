import React from 'react';
import { Box } from '@chakra-ui/react';

const ImageSection = () => {
    return (
        <Box paddingBottom={["50px", "50px", "50px", "60px", "70px", "80px"]} paddingTop={["30px", "40px", "40px", "50px", "50px", "50px"]}>
            <img src={"https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/1.png"} width={["100%"]}/>
        </Box>
    );
};
export default ImageSection;