import React, { useRef, useEffect } from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';

const HeroSection = () => {
    const bannerText = [
        {
            title: 'Build Your Software Products, Faster, Better.',
            description: "We Develop Customized Websites For Your Precise Business Needs",
            button: 'Book A Free Demo',
        },
    ];
    return (
        <Box>
            <Box backgroundImage={["none", "none", "https://d2elmmls1zw4az.cloudfront.net/girafe/web-development/bg2.svg",  "https://d2elmmls1zw4az.cloudfront.net/girafe/web-development/bg2.svg", "https://d2elmmls1zw4az.cloudfront.net/girafe/web-development/bg2.svg", "https://d2elmmls1zw4az.cloudfront.net/girafe/web-development/bg2.svg"]} backgroundSize={["cover"]} backgroundRepeat={["no-repeat"]} backgroundColor={["#000", "#000", "#fff", "#fff", "#fff", "#fff"]}
                backgroundPosition={["right"]}
                paddingTop={["25px", "25px", "30px", "35px", "40px", "45px"]}
                paddingBottom={["25px", "285px"]}
            >
                <Container
                    width={["90%", "90%", "90%", "90%", "90%", "90%"]}
                    maxWidth={["90%", "90%", "90%", "90%", "90%", "90%"]}
                    marginBottom={["30px", "30px", 0, 0, 0, 0]}
                    paddingRight={["0"]}
                >
                    <Box>
                        <Text
                            color={["#ffffff"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["500"]}
                            paddingBottom={["26px"]}
                        >
                            SERVICES / WEB DEVELOPMENT
                        </Text>
                        <hr width={"60%"} />
                    </Box>
                    <Box paddingTop={["43px", "43px", "53px", "53px", "63px", "73px"]} display={["flex"]} flexDirection={["column-reverse", "column-reverse", "row", "row", "row", "row"]} gap={["50px", "50px", "100px", "100px", "100px", "100px"]}>                      <Box width={["100%", "100%", "55%", "55%", "55%", "55%"]}>
                        <Text
                            color={["#ffffff"]}
                            fontSize={["22px", "36px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["500"]}
                            paddingBottom={["30px"]}>
                            {bannerText[0].title}
                        </Text>
                        <Text
                            color={["#ffffff"]}
                            fontSize={["16px", "18px"]}
                            fontFamily={["HouschkaPro-Light"]}
                            lineHeight={["normal"]}
                            paddingBottom={["43px"]}>
                            {bannerText[0].description}
                        </Text>
                        <Button
                            backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}
                        >
                            {bannerText[0].button}
                        </Button>
                    </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
export default HeroSection;