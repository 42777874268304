import React from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import icon from "../../../assets/Icon/webIcon.svg";
const Grow = () => {
    return (
        <Box>
            <Box display={["flex"]} alignItems={["center"]} paddingTop={["40px", "80px"]} paddingBottom={["40px"]} flexDir={["row"]} width={["90%", "90%", "80%", "80%", "80%", "80%"]} margin={["0 auto"]}
                gap={["70px"]}>
                <Box width={["50%"]}>
                    <img src='https://d2elmmls1zw4az.cloudfront.net/girafe/web-development/8.png' />
                </Box>
                <Box width={["50%"]}>
                    <Text
                        color={["#1e1e1e"]}
                        fontSize={["16px", "32px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["600"]}
                        paddingBottom={["26px"]}
                    >
                        Grow Your Brand With Us
                    </Text>
                    <Box backgroundImage="linear-gradient(90deg, #ECF2F7 0%, rgba(255, 255, 255, 0) 100%)" padding={"15px"}>
                        <Text
                            color={["#000000"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["600"]}
                            paddingBottom={["8px"]}
                        >
                            Did you know?
                        </Text>
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "18px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            71% of consumers who have had a good social media service experience with a brand recommend it to others
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} paddingTop={["28px"]} paddingBottom={["40px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Make your brand heard & shared with social media strategy/campaign crafted after in-depth study of your business goals.
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Boost customer engagement & satisfaction, offering them content that they would love to share.
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Box display={["flex"]} alignItems={["center"]} flexDir={["row"]} width={["90%", "90%", "80%", "80%", "80%", "80%"]} margin={["0 auto"]} gap={["70px"]} paddingBottom={["40px", "80px"]} >
                <Box width={["50%"]}>
                    <Text
                        color={["#1e1e1e"]}
                        fontSize={["16px", "32px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["600"]}
                        paddingBottom={["26px"]}
                    >
                        Crafting Experiences That Brings Results
                    </Text>
                    <Box backgroundImage="linear-gradient(90deg, #ECF2F7 0%, rgba(255, 255, 255, 0) 100%)" padding={"15px"}>
                        <Text
                            color={["#000000"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["600"]}
                            paddingBottom={["8px"]}
                        >
                            Did you know?
                        </Text>
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "18px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            You have just 10 seconds before an average consumer forms an opinion about your brand.
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} paddingTop={["28px"]} paddingBottom={["40px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Convey your brand’s message & increase brand’s visibility using intuitive UI/ UX designs crafted by our in-house design studio.
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Give a seamless experience to your customers with internet marketing services which drives more engagement & sales opportunities.
                        </Text>
                    </Box>
                    <Button
                        backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]} marginTop={["45px"]}
                    >
                        Boost Your Customer Experience
                    </Button>
                </Box>
                <Box width={["50%"]}>
                    <img src='https://d2elmmls1zw4az.cloudfront.net/girafe/web-development/9.png' />
                </Box>
            </Box>
        </Box>
    );
};
export default Grow;