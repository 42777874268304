import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const MobileApplication = () => {
    return (
        <Box width={["90%", "90%", "90%", "90%", "85%", "85%"]} margin={["0 auto"]}>
            <Box display={["flex"]} justifyContent={["space-between"]} paddingBottom={["75px"]} flexDir={["column", "column", "row", "row", "row", "row"]}>
                <Text
                    color={["#000000"]}
                    fontSize={["26px", "32px"]}
                    fontFamily={["HouschkaPro-Medium"]}
                    lineHeight={["normal"]}
                    fontWeight={["500"]}
                    width={["100%", "100%", "40%", "40%", "40%", "40%"]}
                    paddingBottom={["25px", 0]}
                >
                    Recipe of the Mobile Application
                </Text>
                <Box height="50px" width="1px" backgroundColor="#5F5F5F" mx="4" display={["none", "none", "block", "block", "block", "block"]}/>
                <Text
                    color={["#333333"]}
                    fontSize={["16px", "18px"]}
                    fontFamily={["HouschkaPro-Medium"]}
                    fontWeight={["300"]}
                    lineHeight={["normal"]}
                    width={["100%", "100%", "40%", "40%", "40%", "40%"]}
                >
                    It is pretty simple — just take a couple of analysts, a pinch of designers, a tablespoon of developers and 100 ml of testers.
                </Text>
            </Box>
            <Box display={["flex"]} flexDirection={["column", "column", "row", "row", "row", "row"]} gap={["32px"]}>
                <Box width={["100%", "100%", "70%", "70%", "70%", "70%"]}>
                    <img src="https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/2.png" />
                </Box>
                <Box display={["flex"]} flexDirection={["column"]} gap={["20px"]} width={["100%", "100%", "30%", "30%", "30%", "30%"]}>
                    <img src="https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/3.svg" />
                    <Box backgroundColor={["#f8f8f8"]} borderRadius={["28px"]} padding={["30px"]}>
                        <Text fontSize={["20px"]} color={["#383838"]} fontFamily={["HouschkaPro-Medium"]}
                            fontWeight={["600"]} paddingBottom={["20px"]}>
                            Quality Assurance
                        </Text>
                        <Text fontSize={["16px"]} color={["#383838"]} fontFamily={["HouschkaPro-Medium"]}
                            fontWeight={["300"]}>
                            The development kicks off! We use a sprint-based approach, with planning and demo meetings every other week, that gives you a great visibility and control over the scope of the project.
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default MobileApplication;