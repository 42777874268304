import React from "react";
import { Box, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Image, ChakraProvider, extendTheme } from '@chakra-ui/react';

import img1 from "../../../assets/Home/Address/1.svg";
import img2 from "../../../assets/Home/Address/india.svg";
import img3 from "../../../assets/Home/Address/canada.svg";
import img4 from "../../../assets/Home/Address/downArrow.svg";
import img5 from "../../../assets/Home/Address/mob.svg";
import img6 from "../../../assets/Home/Address/email.svg";

const theme = extendTheme({
    components: {
        Tabs: {
            baseStyle: {
                tab: {
                    _selected: {
                        border: '1px solid #2469BC',
                        borderRadius: '50%',
                        borderWidth: '1px',
                        padding: '8px',
                    },
                    _focus: {
                        boxShadow: 'none',
                    },
                },
                tablist: {
                    borderBottom: 'none',
                },
            },
        },
    },
});

const Address = () => {
    const sectionHeading = {
        fontSize: ['24px', '24px', '26px', '28px', '32px', '32px'],
        fontFamily: 'HouschkaPro-Medium',
        color: ['#fff'],
        fontWeight: ['400'],
        lineHeight: 'normal'
    };

    return (
        <ChakraProvider theme={theme}>
            <Box backgroundColor="#000000">
                <Box display="flex" flexDirection={["column", "column", "row", "row", "row", "row"]} width={["90%", "90%", "80%", "80%", "80%", "80%"]} margin="0 auto">
                    <Box width={["100%", "100%", "40%", "40%", "40%", "40%"]}>
                        <Text as="h1" sx={sectionHeading} paddingTop={["20px", "20px", 0, 0, 0, 0]}>Power Up Your Business Strategy</Text>
                        <Text
                            fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                            fontFamily='HouschkaPro-Medium'
                            color="#ffffff"
                            fontWeight="400"
                            lineHeight="normal"
                            paddingBottom="38px"
                            paddingTop="10px"
                        >
                            Team Up With Us Today For An Unforgettable Service Experience
                        </Text>
                        <Tabs variant="unstyled">
                            <TabList>
                                <Tab>
                                    <Box
                                        sx={{
                                            borderRadius: '50%',
                                            padding: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Image src={img2} borderRadius="full" />
                                    </Box>
                                </Tab>
                                <Tab>
                                    <Box
                                        sx={{
                                            borderRadius: '50%',
                                            padding: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Image src={img3} borderRadius="full" />
                                    </Box>
                                </Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <Box>
                                        {/* <Box>
                                            <img src={img4} />
                                        </Box> */}
                                        <Box>
                                            <Text as="h1" sx={sectionHeading} paddingTop={["20px"]}>Chandigarh</Text>
                                            <Text
                                                fontSize={['16px', '16px', '18px', '18px', '18px', '20px']}
                                                fontFamily='HouschkaPro-Light'
                                                color="#ffffff"
                                                fontWeight="400"
                                                lineHeight="normal"
                                                paddingBottom="28px"
                                                paddingTop="20px"
                                            >
                                                F-425, Ground floor, B-Square Resource Centre Mohali, Sector 91, Sahibzada Ajit Singh Nagar, Punjab 160071
                                            </Text>
                                        </Box>
                                        <Box display={["flex"]} justifyContent={["row"]} paddingBottom={["18px"]} gap={["10px"]}>
                                            <img src={img5} />
                                            <Text
                                                fontSize={['16px', '16px', '18px', '18px', '18px', '20px']}
                                                fontFamily='HouschkaPro-Light'
                                                color="#ffffff"
                                                fontWeight="400"
                                                lineHeight="normal"
                                            >
                                                +91-99885 56660
                                            </Text>
                                        </Box>
                                        <Box display={["flex"]} justifyContent={["row"]} gap={["10px"]}>
                                            <img src={img6} />
                                            <Text
                                                fontSize={['16px', '16px', '18px', '18px', '18px', '20px']}
                                                fontFamily='HouschkaPro-Light'
                                                color="#ffffff"
                                                fontWeight="400"
                                                lineHeight="normal"
                                            >
                                                social@thegirafe.in
                                            </Text>
                                        </Box>

                                    </Box>

                                </TabPanel>
                                <TabPanel>
                                    <Box>
                                        {/* <Box>
                                            <img src={img4} />
                                        </Box> */}
                                        <Box>
                                            <Text as="h1" sx={sectionHeading} paddingTop={["20px"]}>Canada</Text>
                                            <Text
                                                fontSize={['16px', '16px', '18px', '18px', '18px', '20px']}
                                                fontFamily='HouschkaPro-Light'
                                                color="#ffffff"
                                                fontWeight="400"
                                                lineHeight="normal"
                                                paddingBottom="28px"
                                                paddingTop="20px"
                                            >
                                                 229 Yonge Street, Suite 400, Toronto, Ontario, M5B 1N9, Canada
                                            </Text>
                                        </Box>
                                        <Box display={["flex"]} justifyContent={["row"]} paddingBottom={["18px"]} alignItems="center" gap={["10px"]}>
                                            <img src={img5} />
                                            <Text
                                                fontSize={['16px', '16px', '18px', '18px', '18px', '20px']}
                                                fontFamily='HouschkaPro-Light'
                                                color="#ffffff"
                                                fontWeight="400"
                                                lineHeight="normal"
                                            >
                                                +1 (647) 325-4886
                                            </Text>
                                        </Box>
                                        <Box display={["flex"]} justifyContent={["row"]} gap={["10px"]} alignItems={["center"]}>
                                            <img src={img6} />
                                            <Text
                                                fontSize={['16px', '16px', '18px', '18px', '18px', '20px']}
                                                fontFamily='HouschkaPro-Light'
                                                color="#ffffff"
                                                fontWeight="400"
                                                lineHeight="normal"
                                            >
                                                social@thegirafe.ca
                                            </Text>
                                        </Box>

                                    </Box>

                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                    <Box width={["100%", "100%", "60%", "60%", "60%", "60%"]}>
                        <img src={img1} width="100%" alt="Address" />
                    </Box>
                </Box>
            </Box>
        </ChakraProvider>
    );
};

export default Address;
