import React, { useState, useEffect } from 'react';
import { Box, Container, Text, Image, Button, Flex } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import icon from "../../../assets/Testimonial/2.svg";
import img from "../../../assets/Testimonial/1.png";

const Testimonial = () => {
    const data = [
        {
            img: '../../../assets/Testimonial/1.png',
            name: 'Vinay Kumar',
            designation: 'BSS Head, DNA',
            description: "The next industry revolution will be driven by the connectivity of 5G & beyond. Building robust next-gen networks has become a necessity to realize the true potential of technologies such as cloud, analytics, automation, AI, and drive business growth.",
        },
        {
            img: '../../../assets/Testimonial/1.png',
            name: 'Akhilesh Mahajan',
            designation: 'BSS Head, DNA',
            description: "The next industry revolution will be driven by the connectivity of 5G & beyond. Building robust next-gen networks has become a necessity to realize the true potential of technologies such as cloud, analytics, automation, AI, and drive business growth.",
        },
        {
            img: '../../../assets/Testimonial/1.png',
            name: 'Navjot',
            designation: 'BSS Head, DNA',
            description: "The next industry revolution will be driven by the connectivity of 5G & beyond. Building robust next-gen networks has become a necessity to realize the true potential of technologies such as cloud, analytics, automation, AI, and drive business growth.",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
    };

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <Box>
            <Box width={["80%", "80%", "70%", "70%", "65%", "65%"]} margin={["0 auto"]} paddingTop={["65px"]}>

                <Container
                    width="100%"
                    maxW="100%"
                    marginBottom={["30px", "30px", 0, 0, 0, 0]}
                    paddingRight="0"
                >
                    <Box textAlign="center">
                        <Box>
                            <Image src={icon} height={12} width={12} paddingBottom={["5px"]} />
                            <Text
                                color="#000000"
                                fontSize="22px"
                                fontFamily="HouschkaPro-Medium"
                                fontWeight="500"
                                textAlign={["justify"]}
                                lineHeight={["normal"]}
                            >
                                {data[currentIndex].description}
                            </Text>
                            <Box display={["flex"]} flexDirection={["row"]} gap={["14px"]} alignItems={["center"]} paddingTop={["30px"]}>
                                <Box>
                                    <img src={img} />
                                </Box>
                                <Box display={["flex"]} flexDirection={["column"]} alignItems={["flex-start"]}>
                                    <Text
                                        color="#2469BC"
                                        fontSize="18px"
                                        fontFamily="HouschkaPro-DemiBold"
                                        fontWeight="500"
                                        margin={"0"}
                                    >
                                        {data[currentIndex].name}
                                    </Text>
                                    <Text
                                        color="#6F6F6F"
                                        fontSize="16px"
                                        fontFamily="HouschkaPro-Medium"
                                        fontWeight="300"
                                        margin={"0"}

                                    >
                                        {data[currentIndex].designation}
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                        <Flex justifyContent="space-between" mt={8}>
                            <Button onClick={prevTestimonial} mr={2} leftIcon={<ChevronLeftIcon />}>
                                Prev
                            </Button>
                            <Button onClick={nextTestimonial} ml={2} rightIcon={<ChevronRightIcon />}>
                                Next
                            </Button>
                        </Flex>

                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default Testimonial;
