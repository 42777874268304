import React, { useRef, useEffect } from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';

const HeroSection = () => {
    const bannerText = [
        {
            img: 'https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/appDevelopment.png',
            title: 'We Turn Ideas Into Impactful Mobile & Web Apps',
            description: "We Develop Customized Applications For Your Precise Business Needs",
            button: 'Book A Free Demo',
        },
    ];
    return (
        <Box>
            <Box bg='linear-gradient(90.02deg, #252525 0.02%, #000000 52%, #161616 99.98%)'
            paddingTop={["25px", "25px", "30px", "35px", "40px", "45px"]}
            paddingBottom={["25px"]}
            >
                <Container
                    width={["90%", "90%", "90%", "90%", "90%", "90%"]}
                    maxW={["90%", "90%", "90%", "90%", "90%", "90%"]}
                    marginBottom={["30px", "30px", 0, 0, 0, 0]}
                    paddingRight={["0"]}
                    margin={["0 auto"]}
                >
                    <Box>
                        <Text
                            color={["#ffffff"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["500"]}
                            paddingBottom={["26px"]}
                        >
                            SERVICES / MOBILE APP DEVELOPMENT
                        </Text>
                        <hr />
                    </Box>
                    <Box paddingTop={["43px", "43px", "53px", "53px", "63px", "73px"]} display={["flex"]} flexDirection={["column-reverse", "column-reverse", "row", "row", "row", "row"]} gap={["50px", "50px", "100px", "100px", "100px", "100px"]}>
                        <Box width={["100%", "100%", "50%", "50%", "50%", "50%"]}>
                            <Text
                                color={["#ffffff"]}
                                fontSize={["22px", "36px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                                lineHeight={["normal"]}
                                fontWeight={["500"]}
                                paddingBottom={["30px"]}>
                                {bannerText[0].title}
                            </Text>
                            <Text
                                color={["#ffffff"]}
                                fontSize={["16px", "18px"]}
                                fontFamily={["HouschkaPro-Light"]}
                                lineHeight={["normal"]}
                                paddingBottom={["43px"]}>
                                {bannerText[0].description}
                            </Text>
                            <Button
                                backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}
                            >
                                {bannerText[0].button}
                            </Button>
                        </Box>
                        <Box width={["100%", "100%", "50%", "50%", "50%", "50%"]}>
                            <img src={bannerText[0].img} alt="Banner" /> 
                        </Box>
                    </Box>


                </Container>
            </Box>
        </Box>
    );
};
export default HeroSection;