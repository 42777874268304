import React from "react";
import { Box, Container, Flex, Button, Text, Input, InputGroup, InputRightElement, extendTheme } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import bg from "../../assets/Footer/bg.svg";
import facebook from "../../assets/Footer/SocialIcon/facebook.svg";
import instagram from "../../assets/Footer/SocialIcon/instagram.svg";
import linkedin from "../../assets/Footer/SocialIcon/linkedin.svg";
import youtube from "../../assets/Footer/SocialIcon/youtube.svg";
import x from "../../assets/Footer/SocialIcon/twitter.svg";
import logo from "../../assets/Footer/logo1.png";

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  return (
    <Box backgroundImage={bg} backgroundSize={"cover"} backgroundRepeat={["no-repeat"]} backgroundColor={isHomePage ? "#000" : "#fff"}
    >
      <Box
        paddingTop={["150px"]}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        paddingBottom={["80px"]}
      >
        <Text
          fontSize={['16px', '16px', '18px', '28px', '38px', '48px']}
          fontFamily='HouschkaPro-Medium'
          color="#ffffff"
          fontWeight="400"
          lineHeight="normal"
          paddingBottom="7px"
        >
          Join our team.
        </Text>
        <Text
          fontSize={['16px', '16px', '18px', '18px', '20px', '24px']}
          fontFamily='HouschkaPro-Light'
          color="#ffffff"
          fontWeight="400"
          lineHeight="normal"
          paddingBottom="28px"
        >
          Realize your potential.
        </Text>
        <Button
          backgroundColor="#01A6FB"
          borderRadius="28px"
          color="#ffffff"
          fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
          fontFamily='HouschkaPro-Light'
        >
          Get in Touch
        </Button>
      </Box>
      <Box width={["80%"]} margin={["0 auto"]} borderTop={["1px solid #ffffff"]}>
        <Box paddingTop={["40px", "80px"]} display={["flex"]} justifyContent={["space-between"]} flexDirection={["column", "column", "row", "row", "row", "row"]} paddingBottom={["40px", "80px"]} width={["95%", "100%"]} margin={["0 auto"]} gap={["15px", 0]}>
          <Box display={["flex"]} flexDirection={["row", "row", "row", "row", "row", "row"]} gap={["15px"]} alignItems={["center"]}>
            <Box>
              <img src={facebook} />
            </Box>
            <Box>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                FACEBOOK
              </Text>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                Girafe
              </Text>
            </Box>
          </Box>
          <Box display={["flex"]} flexDirection={["row", "row", "row", "row", "row", "row"]} gap={["15px"]} alignItems={["center"]}>
            <Box>
              <img src={x} />
            </Box>
            <Box>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                X
              </Text>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                Girafe
              </Text>
            </Box>
          </Box>
          <Box display={["flex"]} flexDirection={["row", "row", "row", "row", "row", "row"]} gap={["15px"]} alignItems={["center"]}>
            <Box>
              <img src={youtube} />
            </Box>
            <Box>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                YOUTUBE
              </Text>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                Girafe
              </Text>
            </Box>
          </Box>
          <Box display={["flex"]} flexDirection={["row", "row", "row", "row", "row", "row"]} gap={["15px"]} alignItems={["center"]}>
            <Box>
              <img src={instagram} />
            </Box>
            <Box>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                INSTAGRAM
              </Text>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                being.at.girafe
              </Text>
            </Box>
          </Box>
          <Box display={["flex"]} flexDirection={["row", "row", "row", "row", "row", "row"]} gap={["15px"]} alignItems={["center"]}>
            <Box>
              <img src={linkedin} />
            </Box>
            <Box>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                LINKEDIN
              </Text>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
              >
                Girafe
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width={["100%"]} margin={["0 auto"]} borderTop={["1px solid #3e3e3e"]}>
        <Box width={["90%", "90%", "70%", "70%", "70%", "70%"]} margin={["0 auto"]} paddingTop={["50px"]} display={["flex"]} justifyContent={["space-between"]} flexDir={["column", "column", "row", "row", "row", "row"]} gap={["25px", 0]}>
          <Box>
            <img src={logo} width={["180px"]} />
            <Box backgroundColor={["#F2F5FF"]} padding={["30px"]} marginTop={["50px"]}>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '22px']}
                fontFamily='HouschkaPro-Medium'
                color="#1E1E1E"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["18px"]}
              >
                Newsletter Update
              </Text>
              <InputGroup size="md" maxW="400px" mx="auto">
                <Input
                  placeholder="Email address"
                  borderRadius="23px"
                  paddingRight="4.5rem"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    backgroundColor="#01A6FB"
                    borderRadius="23px"
                    _hover={{ backgroundColor: "#0193E1" }}
                  >
                    Submit
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>
          <Box display={["flex"]} flexDirection={["row"]} gap={["15px", "85px"]} justifyContent={["space-between"]}><Box>
            <Text
              fontSize={['16px', '16px', '18px', '18px', '18px', '22px']}
              fontFamily='HouschkaPro-Medium'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["18px"]}
            >
              Industries
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Food Delivery
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Healthcare
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Pickup and Delivery
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              E-commerce Delivery
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Taxi & Transportation
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Homeservices
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Fitness
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Education
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Real Estate
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Finance
            </Text>
          </Box>
            <Box>
              <Text
                fontSize={['16px', '16px', '18px', '18px', '18px', '22px']}
                fontFamily='HouschkaPro-Medium'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["18px"]}
              >
                Solutions
              </Text>
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                fontFamily='HouschkaPro-Light'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["15px"]}
              >
                SEO
              </Text>
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                fontFamily='HouschkaPro-Light'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["15px"]}
              >
                E-commerce
              </Text>
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                fontFamily='HouschkaPro-Light'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["15px"]}
              >
                Web Development
              </Text>
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                fontFamily='HouschkaPro-Light'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["15px"]}
              >
                Mobile Development
              </Text>
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                fontFamily='HouschkaPro-Light'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["15px"]}
              >
                Network Connectivity
              </Text>
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                fontFamily='HouschkaPro-Light'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["15px"]}
              >
                ERP Solutions
              </Text>
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                fontFamily='HouschkaPro-Light'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["15px"]}
              >
                Custom Software Development
              </Text>
              <Text
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                fontFamily='HouschkaPro-Light'
                color="#ffffff"
                fontWeight="400"
                lineHeight="normal"
                paddingBottom={["15px"]}
              >
                Cloud Service
              </Text>

            </Box></Box>

          <Box>
            <Text
              fontSize={['16px', '16px', '18px', '18px', '18px', '22px']}
              fontFamily='HouschkaPro-Medium'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["18px"]}
            >
              Company
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              About Us
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Careers
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Review & Awards
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Blog
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Portfolio
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Events
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              Why Choose Us
            </Text>
            <Text
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              fontFamily='HouschkaPro-Light'
              color="#ffffff"
              fontWeight="400"
              lineHeight="normal"
              paddingBottom={["15px"]}
            >
              How We Work
            </Text>

          </Box>
        </Box>
        <Box backgroundColor={["#000000"]} padding={["20px"]} marginTop={["50px"]}>
          <Text
            fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
            fontFamily='HouschkaPro-Light'
            color="#ffffff"
            fontWeight="400"
            lineHeight="normal"
            textAlign={["center"]}
          >
            All Rights Reserved. Copyrights 2024 @ The Girafe. Terms And Conditions | Privacy Policy
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
