import React, { useRef, useState } from "react";
import { Box, Container, Flex, Text, Image, useBreakpointValue } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper/modules";

import img1 from "../../../assets/Home/Solution/1.png";
import img2 from "../../../assets/Home/Solution/2.png";
import img3 from "../../../assets/Home/Solution/3.png";
import img4 from "../../../assets/Home/Solution/4.png";
import view from "../../../assets/Home/Solution/view.svg";

const ExploreOtherWork = () => {
    const eowData = [
        {
            image: img1,
            title: 'Mechanical Lab Equipment Manufacturer',
            description: "With a legacy of excellence spanning 68 years, we pride ourselves on",
        },
        {
            image: img2,
            title: 'Smart Watches Website',
            description: "At Nervfit, we're dedicated to redefining the way you interact with technology.",
        },
        {
            image: img3,
            title: 'Hashmate Cricket Bat',
            description: "We want your cricket innings to be top-notch, and we do it by providing you with the",
        },
        {
            image: img4,
            title: 'Hashmate Cricket Bat',
            description: "We want your cricket innings to be top-notch, and we do it by providing you with the",
        },
    ];

    const sectionHeading = {
        fontSize: ['24px', '26px', '28px', '30px', '32px', '32px'],
        fontFamily: 'HouschkaPro-Medium',
        color: ['#fff'],
        fontWeight: ['400'],
        lineHeight: 'normal'
    };
    const sectionHeading1 = {
        fontSize: ['16px', '16px', '16px', '16px', '16px', '16px'],
        fontFamily: 'HouschkaPro-Light',
        color: ['#ffffff'],
        fontWeight: ['400'],
        lineHeight: 'normal'
    };
    const viewLink = {
        background: '#00763D',
        color: '#fff',
        fontSize: ['20px', '20px', '20px', '20px', '20px', '20px'],
        padding: ['6px 33px 11px 33px'],
        fontFamily: 'var(--foundersGrotesk-regular);',
        display: 'inline-block',
    };
    const swipperSliderCustomeStyle = {
        paddingLeft: useBreakpointValue({
            base: "calc(5%)",
            sm: "calc(6.5%)",
            md: "calc(6.5%)",
        }),
        paddingRight: useBreakpointValue({
            base: "calc(5% + 10px)",
            sm: "calc(5% + 10px)",
            md: "calc(5% + 15px)",
        }),
    };
    const swiperRef = useRef(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    return (
        <Box paddingBottom={["100px", "100px", "100px", "120px", "150px", "145px"]} overflowX={["hidden"]} background={["#1E1E1E"]}>
            {/* Section Heading */}
            <Container maxW={["100%", "100%", "90%", "90%", "90%", "90%"]} m={'0 auto'}>
                <Flex justifyContent={'space-between'} alignItems={["flex-start", "flex-start", "flex-start", "flex-start", "flex-start", "flex-start"]} borderTop={["none", "1px solid #B5B5B5"]} p={['40px 0 30px', '40px 0 30px', '40px 0 20px', '40px 0 20px', '50px 0 20px', '60px 0 20px']} flexDirection={["column", "column", "row", "row", "row", "row"]} gap={["20px", "20px", 0, 0, 0, 0]}>
                    <Box>
                        <Text as="h1" sx={sectionHeading} width={["90", "73%"]}>A leading branding agency of India -providing a one-stop solution.</Text>
                    </Box>
                    <Box>
                        <Text sx={sectionHeading1}>Discover the digital possibilities for your brand with our compre- hensive suite of services.</Text>
                    </Box>
                </Flex>
            </Container>
            <Container maxW={["100%", "100%", "100%", "100%", "100%", "100%"]} mr={'0'} className="exploreOtherWorks" padding={0}>
                {/* Slider */}
                <Box>
                    <Swiper
                        ref={swiperRef}
                        observeParents={true}
                        observer={true}
                        modules={[Navigation, FreeMode]}
                        speed={750}
                        slidesPerView={2.7}
                        spaceBetween={24}
                        style={swipperSliderCustomeStyle}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.2,
                                spaceBetween: 20,
                                freeMode: false,
                            },
                            480: {
                                slidesPerView: 1.4,
                                spaceBetween: 20,
                                freeMode: false,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 24,
                            },
                            992: {
                                slidesPerView: 4,
                                spaceBetween: 24,
                            },
                        }}
                    >
                        {eowData.map((data, index) => (
                            <SwiperSlide key={index} style={{ position: 'relative' }} onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}>
                                <Box position="relative" overflow="hidden">
                                    <Image
                                        src={data.image}
                                        objectFit="cover"
                                        transform={hoveredIndex === index ? 'scale(1.1)' : 'scale(1)'}
                                        transition="transform 0.8s ease"
                                        filter={hoveredIndex === index ? 'brightness(50%)' : 'brightness(100%)'}
                                    />
                                    {/* <Text
                                        style={{
                                            position: 'absolute',
                                            bottom: '90px',
                                            left: 0,
                                            width: '100%',
                                            //  background: 'rgba(0, 0, 0, 0.7)',
                                            color: '#ffffff',
                                            padding: '10px',
                                            boxSizing: 'border-box'
                                        }}
                                        fontSize={['16px', '16px', '16px', '16px', '20px', '22px']}
                                        fontFamily='HouschkaPro-DemiBold'
                                        fontWeight={["400"]}
                                        lineHeight={'normal'}
                                    >
                                        {data.title}
                                    </Text> */}
                                </Box>
                                {/* <Text
                                    style={{
                                        position: 'absolute',
                                        bottom: '40px',
                                        left: 0,
                                        width: '100%',
                                        //  background: 'rgba(0, 0, 0, 0.7)',
                                        color: '#ffffff',
                                        padding: '10px',
                                        boxSizing: 'border-box'
                                    }}
                                    fontSize={['16px', '16px', '16px', '16px', '16px', '16px']}
                                    fontFamily='HouschkaPro-Medium'
                                    color={["#ffffff"]}
                                    fontWeight={["400"]}
                                    lineHeight={'normal'}
                                >
                                    {data.description}
                                </Text>
                                <img src={readMore}
                                    style={{
                                        position: 'absolute',
                                        bottom: '0px',
                                        left: 0,
                                        //  background: 'rgba(0, 0, 0, 0.7)',
                                        padding: '10px',
                                    }}
                                /> */}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <Box
                        position="absolute"
                        left="50%"
                        transform="translateX(-50%)"
                        pt={['20px', '45px']}
                        margin="0 auto"
                        width={['30%', '10%']}
                    >
                        <img src={view} alt="View Icon" width="100%" />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default ExploreOtherWork;
