import React from 'react';
import { Box, Text, Button } from '@chakra-ui/react';

const Notify = () => {
    return (
        <Box>
            <Box paddingBottom={["80px"]} paddingTop={["80px"]}>
                <Box display={["flex"]} flexDirection={["column", "column", "row", "row", "row", "row"]} alignItems={["center"]} backgroundImage={["https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/bg.svg"]} backgroundSize={["cover"]} backgroundRepeat={["no-repeat"]} paddingTop={["60px"]} paddingBottom={["60px"]} justifyContent={["space-between"]} paddingLeft={["50px", "50px", "287px", "287px", "287px", "287px"]} paddingRight={["50px", "50px", "287px", "287px", "287px", "287px"]}>
                    <Box display={["flex"]} flexDirection={["column"]}>
                        <Text fontSize={["30px"]} color={["#ffffff"]} fontFamily={["HouschkaPro-Medium"]}
                            fontWeight={["500"]} paddingBottom={["20px"]}>
                            Didn’t Find Your Industry Solution?
                        </Text>
                        <Text fontSize={["16px"]} color={["#ffffff"]} fontFamily={["HouschkaPro-Medium"]}
                            fontWeight={["300"]} width={["100%", "100%", "60%", "60%", "60%", "60%"]}>
                            Discuss your mobile app development requirements with our team & get the finest fit for your business
                        </Text>
                    </Box>
                    <Box paddingTop={["25px", 0]}>
                        <Button
                            backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}
                        >
                            Get Started &nbsp; <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3536 4.35355C18.5488 4.15829 18.5488 3.84171 18.3536 3.64645L15.1716 0.464467C14.9763 0.269205 14.6597 0.269205 14.4645 0.464467C14.2692 0.659729 14.2692 0.976312 14.4645 1.17157L17.2929 4L14.4645 6.82843C14.2692 7.02369 14.2692 7.34027 14.4645 7.53554C14.6597 7.7308 14.9763 7.7308 15.1716 7.53554L18.3536 4.35355ZM-4.74796e-08 4.5L18 4.5L18 3.5L4.74796e-08 3.5L-4.74796e-08 4.5Z" fill="white" />
                            </svg>

                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box width={["90%", "90%", "90%", "90%", "85%", "85%"]} margin={["0 auto"]}>
                <Box display={["flex"]} justifyContent={["space-between"]} paddingBottom={["25px"]} flexDirection={["column", "column", "row", "row", "row", "row"]} >
                    <Text
                        color={["#000000"]}
                        fontSize={["26px", "32px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["500"]}
                        width={["100%", "100%", "50%", "50%", "50%", "50%"]}
                        paddingBottom={["25px", 0]}
                    >
                        A Complete Feature-Suite To Build Your Custom Mobile Apps
                    </Text>
                    <Text
                        color={["#333333"]}
                        fontSize={["16px", "18px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        fontWeight={["300"]}
                        lineHeight={["normal"]}
                        width={["100%", "100%", "35%", "35%", "35%", "35%"]}
                    >
                        We offer end-to-end features to drive engagement & revenue for your brand
                    </Text>
                </Box>
                <Box display={["flex"]} justifyContent={["row"]} gap={["34px"]} flexDirection={["column", "column", "row", "row", "row", "row"]}>
                    <Box>
                        <img src='https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/5.svg' />
                    </Box>
                    <Box>
                        <img src='https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/4.svg' />
                    </Box>
                </Box>
            </Box>

            <Box marginTop={["25px"]}>
                <img src='https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/6.svg' width={["100%"]} />
            </Box>
        </Box>
    );
};
export default Notify;