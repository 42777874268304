import React from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import icon1 from "../../../assets/Home/Icon/Industry/1.svg";
import icon2 from "../../../assets/Home/Icon/Industry/2.svg";
import icon3 from "../../../assets/Home/Icon/Industry/icon3.svg";
import icon4 from "../../../assets/Home/Icon/Industry/icon4.svg";
import icon5 from "../../../assets/Home/Icon/Industry/icon5.svg";
import icon6 from "../../../assets/Home/Icon/Industry/icon6.svg";
import icon7 from "../../../assets/Home/Icon/Industry/icon7.svg";
import icon8 from "../../../assets/Home/Icon/Industry/icon8.svg";
import icon9 from "../../../assets/Home/Icon/Industry/icon9.svg";
import icon10 from "../../../assets/Home/Icon/Industry/icon10.svg";
import icon11 from "../../../assets/Home/Icon/Industry/icon11.svg";
import icon12 from "../../../assets/Home/Icon/Industry/icon12.svg";

import arrow from "../../../assets/Home/Icon/Industry/arrow.svg";

const HeroSection = () => {
    return (
        <Box paddingTop={["40px", "40px", "40px", "50px", "60px", "80px"]} paddingBottom={["60px", "60px", "60px", "60px", "60px", "80px"]} width={["85%"]} margin={["0 auto"]}>
            <Box>
                <Container
                    width={["100%", "100%", "100%", "100%", "100%", "100%"]}
                    maxW={["100%", "100%", "100%", "100%", "100%", "100%"]}
                    marginBottom={["5px", "5px", 0, 0, 0, 0]}
                    padding={0}
                    paddingRight={["0"]}
                >
                    <Text color={["#000000"]}
                        fontSize={["24px", "32px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        paddingBottom={["20px", "30px", "30px", "40px", "40px", "40px"]}>
                        Select your industry. Discover our impact.
                    </Text>
                </Container>
                <Box display={["flex"]} flexDirection={["column", "column", "column", "row", "row", "row"]} gap={["20px", "40px", "55px", "65px", "75px", "80px"]} justifyContent={["space-between"]}>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon1} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Banking</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon2} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Capital Markets</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                </Box>
                <Box display={["flex"]} flexDirection={["column", "column", "column", "row", "row", "row"]} gap={["20px", "40px", "55px", "65px", "75px", "80px"]} justifyContent={["space-between"]} paddingTop={["20px", "30px"]}>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon3} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Education</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon4} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Consumer Goods and Distribution</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                </Box>
                <Box display={["flex"]} flexDirection={["column", "column", "column", "row", "row", "row"]} gap={["20px", "40px", "55px", "65px", "75px", "80px"]} justifyContent={["space-between"]} paddingTop={["20px", "30px"]}>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon5} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Healthcare</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon6} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Energy, Resources, and Utilities</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                </Box>
                <Box display={["flex"]} flexDirection={["column", "column", "column", "row", "row", "row"]} gap={["20px", "40px", "55px", "65px", "75px", "80px"]} justifyContent={["space-between"]} paddingTop={["20px", "30px"]}>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon7} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Insurance</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon8} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >High Tech</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                </Box>
                <Box display={["flex"]} flexDirection={["column", "column", "column", "row", "row", "row"]} gap={["20px", "40px", "55px", "65px", "75px", "80px"]} justifyContent={["space-between"]} paddingTop={["20px", "30px"]}>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon9} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Manufacturing</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon10} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Life Sciences</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                </Box>
                <Box display={["flex"]} flexDirection={["column", "column", "column", "row", "row", "row"]} gap={["20px", "40px", "55px", "65px", "75px", "80px"]} justifyContent={["space-between"]} paddingTop={["20px", "30px"]}>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon11} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Retail</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} alignItems={["center"]} borderBottom={["1px solid #DBDBDB"]} p={["10px", "20px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} alignItems={["center"]}>
                            <Image src={icon12} width={["25px"]} height={["25px"]} />
                            <Text
                                color={["#000000"]}
                                fontSize={["18px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                            >Travel and Logistics</Text>
                        </Box>
                        <Box>
                            <Image src={arrow} />
                        </Box>
                    </Box>
                </Box>

            </Box>

        </Box>
    );
};
export default HeroSection;