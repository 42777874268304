import React from "react";
import { Box, Container, Flex } from '@chakra-ui/react';

import img1 from "../../../assets/Home/CaseStudy/1.svg";

const ExploreOtherWork = () => {
    return (
        <Box>
            <Box display={["none", "none", "block", "block", "block", "block"]}>
                <img src={img1} width={["100%"]} />
            </Box>
            <Box display={["block", "block",  "none", "none", "none", "none"]}>
                <img src={"https://d2elmmls1zw4az.cloudfront.net/girafe/casestudy/2.png"} width={["100%"]} />
            </Box>
        </Box>

    );
};

export default ExploreOtherWork;
