import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import HeroSection from "./DetailPage/HeroSection";
import Seo from "./DetailPage/ImageSection";
import SolutionSection from "./DetailPage/SolutionSection";
const App = () => {
  return (
    <>
      <Box>
          <HeroSection />
      </Box>

      <Box>
        <Seo />
      </Box>

      <Box>
        <SolutionSection />
      </Box>

    </>
  );
};

export default App;