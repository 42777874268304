import React, { useRef, useEffect } from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import bg from "../../../assets/Seo/bg.png";
const HeroSection = () => {
    const bannerText = [
        {
            img: 'https://d2elmmls1zw4az.cloudfront.net/girafe/app-development/appDevelopment.png',
            title: 'Work With An SEO Company Full of Experts You Can Trust',
            description: "We offer professional seo services that will help you outrank your competition and grow your business online.",
            button: 'Book A Free Demo',
        },
    ];
    return (
        <Box>
            <Box backgroundImage={[bg]} backgroundSize={["cover"]} backgroundRepeat={["no-repeat"]}
                p={['23px 0', '23px 0', '23px 0', '25px 0 53px', '113px 0 83px', '85px 207px 50px']}
            >
                <Container
                    width={["100%", "100%", "100%", "100%", "100%", "100%"]}
                    maxW={["100%", "100%", "100%", "100%", "100%", "100%"]}
                    marginBottom={["30px", "30px", 0, 0, 0, 0]}
                    paddingRight={["0"]}
                >
                    <Box>
                        <Text
                            color={["#ffffff"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["500"]}
                            paddingBottom={["26px"]}
                        >
                            SERVICES / SEO
                        </Text>
                        <hr />
                    </Box>
                    <Box paddingTop={["73px"]} display={["flex"]} flexDirection={["row"]} gap={["100px"]}>
                        <Box width={["55%"]}>
                            <Text
                                color={["#ffffff"]}
                                fontSize={["16px", "36px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                                lineHeight={["normal"]}
                                fontWeight={["500"]}
                                paddingBottom={["30px"]}>
                                {bannerText[0].title}
                            </Text>
                            <Text
                                color={["#ffffff"]}
                                fontSize={["16px", "18px"]}
                                fontFamily={["HouschkaPro-Light"]}
                                lineHeight={["normal"]}
                                paddingBottom={["43px"]}>
                                {bannerText[0].description}
                            </Text>
                            <Button
                                backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}
                            >
                                {bannerText[0].button}
                            </Button>
                        </Box>
                        <Box width={["45%"]}>
                            <img src={bannerText[0].img} alt="Banner" /> {/* Displaying the image */}
                        </Box>
                    </Box>


                </Container>
            </Box>
        </Box>
    );
};
export default HeroSection;