import React, { useRef, useEffect } from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Custom.css";

const HeroSection = () => {
  const bannerText = [
    {
      img: 'https://d2elmmls1zw4az.cloudfront.net/girafe/slider/7.svg',
      title: 'More Human Wisdom / Less Machine Logic',
      description: "We pride ourselves on offering a holistic solution under one roof, encompassing every aspect of branding to elevate your business to new heights.",
      button: 'Schedule A Call',
    },
    {
      img: 'https://d2elmmls1zw4az.cloudfront.net/girafe/slider/8.svg',
      title: 'More Human Wisdom / Less Machine Logic',
      description: "We pride ourselves on offering a holistic solution under one roof, encompassing every aspect of branding to elevate your business to new heights.",
      button: 'Schedule A Call',
    },
    {
      img: 'https://d2elmmls1zw4az.cloudfront.net/girafe/slider/9.svg',
      title: 'More Human Wisdom / Less Machine Logic',
      description: "We pride ourselves on offering a holistic solution under one roof, encompassing every aspect of branding to elevate your business to new heights.",
      button: 'Schedule A Call',
    }
  ];

  return (
    <Box>
      <Box bg='#000000'
        p={['23px 0', '23px 0', '23px 0', '25px 0 53px', '43px 0 83px', '43px 0 100px']}
        //  height={["auto", "auto", "auto", "75vh", "80vh", "95vh"]}
        className='homePageSlider'
      >
        <Container
          width={["100%", "100%", "100%", "100%", "100%", "100%"]}
          maxW={["100%", "100%", "100%", "100%", "100%", "100%"]}
          marginBottom={["30px", "30px", 0, 0, 0, 0]}
          paddingRight={["0"]}
        >
          <Swiper
            observeParents={true}
            observer={true}
            speed={750}
            slidesPerView={1.2}
            spaceBetween={50}
            breakpoints={{
              120: { slidesPerView: 1.2, spaceBetween: 20, freeMode: false },
              320: { slidesPerView: 1.2, spaceBetween: 20, freeMode: false },
              480: { slidesPerView: 1.2, spaceBetween: 30, freeMode: false },
              768: { slidesPerView: 1.2, spaceBetween: 40 },
              992: { slidesPerView: 1.2, spaceBetween: 50 },
            }}
          >
            {bannerText.map((banner, index) => (
              <SwiperSlide key={index}>
                <Box position="relative" textAlign="left">
                  <Image src={banner.img} />

                  <Box
                    position="absolute"
                    top="140px"
                    left="15px"
                    p="3"
                    color="white"
                    opacity={0}
                    transition="opacity 0.5s"
                    className="overlay"
                    css={`
            &:hover {
                opacity: 1;
            }
        `}
                  >
                    <Text
                      color={["#ffffff"]}
                      fontSize={["24px", "24px", "24px", "24px", "24px", "24px"]}
                      fontWeight={["400"]}
                      fontFamily={["HouschkaPro-Light"]}
                      lineHeight={["normal"]}
                      width={["80%", "80%", "80%", "80%", "80%", "80%"]}
                      paddingBottom={["35px"]}
                    >
                      {banner.description}
                    </Text>
                    <Button
                      backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}
                    >{banner.button}</Button>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>
      <Box
        paddingTop={["20px", "30px", "40px", "50px", "50px", "55px"]}
        paddingBottom={["20px", "30px", "40px", "50px", "50px", "55px"]}
        width={["90%", "90%", "90%", "45%", " 45%", "44%"]} margin={["0 auto"]}>
        <Text
          color={["#000000"]}
          fontSize={["18px", "22px"]}
          fontFamily={["HouschkaPro-Light"]}
          fontWeight={["300"]}
        >
          We have spent years walking through our lives, room by room, drawing up a list of work to be done, fixing things day by day to shape our vision & now <Box
            as="span"
            color="#01A6FB"
            fontWeight="500"
            fontFamily="HouschkaPro-DemiBold"
            fontSize={["19px", "23px"]}
          >
            WE WON'T EVER STOP.
          </Box>

        </Text>
      </Box>
    </Box>
  );
};
export default HeroSection;