import React from "react";
import { Box, Container, Flex, Button, Text } from '@chakra-ui/react';

import img1 from "../../../assets/Home/Digital/bg.png";
import img2 from "../../../assets/Home/Digital/2.png";
import mob from "../../../assets/Home/Digital/mob.png";
import mob2 from "../../../assets/Home/Digital/mob2.png";

import "./Custom.css";

const ExploreOtherWork = () => {
    return (
        <Box>
            <Box paddingTop={["40px", "60px", "60px", "80px", "80px", "80px"]}>
                <Box display={["none", "none", "flex", "flex", "flex", "flex"]} flexDirection={["column-reverse", "column-reverse", "row", "row", "row", "row"]} width={["90%", "90%", "70%", "70%", "70%", "70%"]} margin={["0 auto"]} alignItems={["center"]} backgroundImage={img1} backgroundSize={"cover"} backgroundRepeat={"no-repeat"}>
                    <Box className="digitalSolution" visibility={["hidden"]}>
                        <Text paddingTop={["134px"]}
                            fontSize={['16px', '16px', '30px', '36px', '40px', '47px']}
                            fontFamily='HouschkaPro-Medium'
                            color={["#000000"]}
                            fontWeight={["400"]}
                            lineHeight={'normal'}
                            paddingBottom={["45px"]}
                        >
                            We Empower Your Business With Out-Of-The-Box Digital Solutions
                        </Text>
                        <Button
                            borderRadius={"36px"}
                            border={"1px solid #000000"}
                            color={"#000000"}
                            fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                            padding={"20px"}
                        >
                            Get in Touch
                        </Button>
                    </Box>
                    <Box position={["static", "static", "relative", "relative", "relative", "relative"]} left={["-95px"]}>
                        <Text
                            fontSize={['16px', '16px', '30px', '36px', '40px', '47px']}
                            fontFamily='HouschkaPro-Medium'
                            color={["#000000"]}
                            fontWeight={["400"]}
                            lineHeight={'normal'}
                            paddingBottom={["45px"]}
                        >
                            We Empower Your Business With Out-Of-The-Box Digital Solutions
                        </Text>
                        <Button
                            borderRadius={"36px"}
                            border={"1px solid #000000"}
                            color={"#000000"}
                            fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                            padding={"20px"}
                        >
                            Get in Touch
                        </Button>
                    </Box>
                </Box>
                <Box display={[ "flex", "flex", "none", "none", "none", "none"]} flexDirection={["column-reverse", "column-reverse", "row", "row", "row", "row"]} width={["95%", "95%", "70%", "70%", "70%", "70%"]} margin={["0 auto"]} alignItems={["center"]} backgroundImage={mob} backgroundSize={"cover"} backgroundRepeat={"no-repeat"} justifyContent={["space-between"]} height={["680px"]}>
                    <Box className="digitalSolution" visibility={["hidden"]}>
                        <Text paddingTop={["134px"]}
                            fontSize={['16px', '16px', '30px', '36px', '40px', '47px']}
                            fontFamily='HouschkaPro-Medium'
                            color={["#000000"]}
                            fontWeight={["400"]}
                            lineHeight={'normal'}
                            paddingBottom={["45px"]}
                        >
                            We Empower Your Business With Out-Of-The-Box Digital Solutions
                        </Text>
                        <Button
                            borderRadius={"36px"}
                            border={"1px solid #000000"}
                            color={"#000000"}
                            fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                            padding={"20px"}
                        >
                            Get in Touch
                        </Button>
                    </Box>
                    <Box position={["static", "static", "relative", "relative", "relative", "relative"]} left={["-95px"]} width={["90%"]} margin={["0 auto"]}>
                        <Text
                            fontSize={['28px', '28px', '30px', '36px', '40px', '47px']}
                            fontFamily='HouschkaPro-Medium'
                            color={["#000000"]}
                            fontWeight={["400"]}
                            paddingTop={["54px"]}
                            lineHeight={'normal'}
                            paddingBottom={["45px"]}
                        >
                            We Empower Your Business With Out-Of-The-Box Digital Solutions
                        </Text>
                        <Button
                            borderRadius={"36px"}
                            border={"1px solid #000000"}
                            color={"#000000"}
                            fontSize={['16px', '16px', '18px', '18px', '18px', '18px']}
                            padding={"20px"}
                        >
                            Get in Touch
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box paddingTop={["40px", "60px", "60px", "70px", "70px", "70px"]} display={["none", "none", "flex", "flex", "flex", "flex"]} >
                <img src={img2} width={["100%"]}/>
            </Box>
            <Box paddingTop={["40px", "60px", "60px", "70px", "70px", "70px"]} display={[ "flex", "flex", "none", "none", "none", "none"]} >
                <img src={mob2} width={["100%"]}/>
            </Box>
        </Box>
    );
};

export default ExploreOtherWork;
