import React from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import icon from "../../../assets/Icon/webIcon.svg";
import img11 from "../../../assets/DigitalMarketing/img11.png";
const Grow = () => {
    return (
        <Box>
            <Box display={["flex"]} alignItems={["center"]} flexDir={["row"]} width={["90%", "90%", "90%", "80%", "80%", "80%"]} margin={["0 auto"]} paddingTop={["60px"]} paddingBottom={["60px"]}
                gap={["70px"]}>

                <Box width={["50%"]}>
                    <Text
                        color={["#1e1e1e"]}
                        fontSize={["16px", "32px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["600"]}
                        paddingBottom={["26px"]}
                    >
                        Grow Your Brand With Social Media
                    </Text>
                    <Box backgroundImage="linear-gradient(90deg, #ECF2F7 0%, rgba(255, 255, 255, 0) 100%)" padding={"15px"}>
                        <Text
                            color={["#000000"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["600"]}
                            paddingBottom={["8px"]}
                        >
                            Did you know?
                        </Text>
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "18px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            71% of consumers who have had a good social media service experience with a brand recommend it to others
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} paddingTop={["28px"]} paddingBottom={["40px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Make your brand heard & shared with social media strategy/campaign crafted after in-depth study of your business goals.
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Boost customer engagement & satisfaction, offering them content that they would love to share.
                        </Text>
                    </Box>
                </Box>
                <Box width={["50%"]}>
                    <img src={img11} />
                </Box>
            </Box>

        </Box>
    );
};
export default Grow;