import React from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import icon from "../../../assets/Icon/webIcon.svg";
import img5 from "../../../assets/DigitalMarketing/img5.png";
import img6 from "../../../assets/DigitalMarketing/img6.png";

const Grow = () => {
    return (
        <Box>
            <Box display={["flex"]} alignItems={["center"]} flexDir={["row"]} maxW={["100%", "100%", "80%", "80%", "80%", "80%"]} m={'0 auto'} gap={["70px"]} paddingTop={["35px"]}>
                
                <Box width={["50%"]}>
                    <Text
                        color={["#1e1e1e"]}
                        fontSize={["16px", "32px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["600"]}
                        paddingBottom={["26px"]}
                    >
                        Digital Marketing Agency
                    </Text>
                    <Text
                        color={["#717171"]}
                        fontSize={["16px", "18px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["400"]}
                    >
                        Partner with a leading digital marketing agency to increase brand awareness, reach your target audience & grow your business online.
                    </Text>

                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} paddingTop={["28px"]} paddingBottom={["20px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Optimize Targeted Audiences
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} paddingBottom={["20px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Generate Qualified Leads
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} paddingBottom={["20px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Boost Brand Authority
                        </Text>
                    </Box>
                </Box>
                <Box width={["50%"]}>
                    <img src={img6} />
                </Box>
            </Box>
            <Box display={["flex"]} alignItems={["center"]} flexDir={["row"]} maxW={["100%", "100%", "80%", "80%", "80%", "80%"]} m={'0 auto'} gap={["70px"]} paddingTop={["35px"]} paddingBottom={["80px"]}>
                <Box width={["50%"]}>
                    <img src={img5} />
                </Box>
                <Box width={["50%"]}>
                    <Text
                        color={["#1e1e1e"]}
                        fontSize={["16px", "32px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["600"]}
                        paddingBottom={["26px"]}
                    >
                        Fueling Growth Through Digital Excellence
                    </Text>

                    <Text
                        color={["#717171"]}
                        fontSize={["16px", "18px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["400"]}
                    >
                        You have just 10 seconds before an average consumer forms an opinion about your brand.
                    </Text>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]} paddingTop={["28px"]} paddingBottom={["20px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Convey your brand’s message & increase brand’s visibility using intuitive UI/ UX designs crafted by our in-house design studio.
                        </Text>
                    </Box>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["10px"]}>
                        <img src={icon} />
                        <Text
                            color={["#717171"]}
                            fontSize={["16px", "16px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            Give a seamless experience to your customers with internet marketing services which drives more engagement & sales opportunities.
                        </Text>
                    </Box>
                    <Button
                        backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]} marginTop={["25px"]}
                    >
                        Boost Your Customer Experience
                    </Button>
                </Box>

            </Box>
        </Box>
    );
};
export default Grow;