import React, { useState } from "react";
import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Box,
    Text,
    extendTheme,
    ThemeConfig,
    ChakraProvider,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    VStack,
    useToast,
} from "@chakra-ui/react";

const theme = extendTheme({
    colors: {
        brand: {
            500: "#01A6FB",
        },
    },
    components: {
        Tabs: {
            baseStyle: {
                tab: {
                    fontSize: "20px",
                    color: "#000000",
                    fontFamily: "HouschkaPro-Medium",
                    paddingBottom: "10px",
                    _selected: {
                        color: "brand.500",
                    },
                },
            },
            variants: {
                line: {
                    tab: {
                        borderBottom: "2px solid",
                        borderColor: "brand.500",
                    },
                },
            },
        },
    },
});
const FormSection = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };
    const [formData, setFormData] = useState({
        name: "",
        company: "",
        email: "",
        phone: "",
        message: "",
    });

    const toast = useToast();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toast({
            title: "Form Submitted",
            description: JSON.stringify(formData, null, 2),
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        setFormData({
            name: "",
            company: "",
            email: "",
            phone: "",
            message: "",
        });
    };

    return (
        <Box width={["90%", "90%", "90%", "90%", "85%", "85%"]} margin={["0 auto"]} paddingTop={["40px", "40px", "60px", "60px", "80px", "80px"]} paddingBottom={["40px", "40px", "50px", "50px", "50px", "50px"]}>
            <Box display={["flex"]} flexDirection={["column", "column", "row", "row", "row", "row"]} justifyContent={["space-between"]}>
                <Box width={["100%", "100%", "100%", "40%", "40%", "40%"]} paddingBottom={["25px", 0]}>
                    <Text
                        fontSize={["26px", "32px"]} color={["#000000"]} fontFamily={["HouschkaPro-Medium"]} paddingBottom={["10px"]} lineHeight={["normal"]}
                    >
                        Mobile App Development Services
                    </Text>
                    <Text
                        fontSize={["18px"]} color={["#333333"]} fontFamily={["HouschkaPro-Medium"]} fontWeight={["300"]} paddingBottom={["40px"]}
                    >
                        Whether iOs, Android or hybrid, we’ll design & develop a custom app for your brand that encourages downloads and increases engagement.
                    </Text>
                    <ChakraProvider theme={theme}>
                        <Tabs
                            onChange={handleTabChange}
                            index={selectedTab}
                            variant="line"
                        >
                            <TabList>
                                <Tab>Android App</Tab>
                                <Tab>iOS App</Tab>
                                <Tab>Hybrid App</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Box>Our team creates custom mobile apps that provide high retention rates and are well-positioned for virality with innovative convenience, speed and ease of browsing.</Box>
                                </TabPanel>
                                <TabPanel>
                                    <Box>Our team creates custom mobile apps that provide high retention rates and are well-positioned for virality with innovative convenience, speed and ease of browsing.</Box>
                                </TabPanel>
                                <TabPanel>
                                    <Box>Our team creates custom mobile apps that provide high retention rates and are well-positioned for virality with innovative convenience, speed and ease of browsing.</Box>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ChakraProvider>
                </Box>
                <Box width={["100%", "100%", "100%", "50%", "50%", "50%"]} boxShadow={["0px 0px 50px 15px rgba(0,0,0,0.1)"]} borderRadius={["15px"]} padding={["25px", "25px", "25px", "30px", "35px", "35px"]}>
                    <Box>
                        <Text
                            fontSize={["32px"]} color={["#000000"]} fontFamily={["HouschkaPro-Medium"]} paddingBottom={["10px"]}
                        >
                            Request A Quote
                        </Text>
                        <Text
                            fontSize={["18px"]} color={["#333333"]} fontFamily={["HouschkaPro-Medium"]} fontWeight={["300"]} paddingBottom={["40px"]}
                        >
                            Receive Expert Recommendations & Competitor Analysis
                        </Text>
                        <form onSubmit={handleSubmit}>
                            <VStack spacing="4" alignItems="flex-start">
                                <FormControl id="name" isRequired>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Name"
                                    />
                                </FormControl>
                                <FormControl id="company">
                                    <Input
                                        type="text"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                        placeholder="Company"
                                    />
                                </FormControl>
                                <FormControl id="email" isRequired>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email Address"
                                    />
                                </FormControl>
                                <FormControl id="phone">
                                    <Input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder="Phone Number"
                                    />
                                </FormControl>
                                <FormControl id="message" isRequired>
                                    <Textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        placeholder="Message"
                                    />
                                </FormControl>
                                <Button type="submit" backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}>
                                    Submit
                                </Button>
                            </VStack>
                        </form>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};
export default FormSection;