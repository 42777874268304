import React from "react";
import { Box, Container, Flex } from '@chakra-ui/react';

import img1 from "../../../assets/WebDevelopment/caseStudy.png";

const ExploreOtherWork = () => {
    return (
        <Box>
            <img src={img1} width={["100%"]}/>
        </Box>
    );
};

export default ExploreOtherWork;
