import React, { useRef, useEffect } from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import icon1 from "../../../assets/Icon/webDevelopment/icon1.svg";
import icon2 from "../../../assets/Icon/webDevelopment/icon2.svg";
import icon3 from "../../../assets/Icon/webDevelopment/icon3.svg";
import icon4 from "../../../assets/Icon/webDevelopment/icon4.svg";
import icon5 from "../../../assets/Icon/webDevelopment/icon5.svg";
import icon6 from "../../../assets/Icon/webDevelopment/icon6.svg";
import icon7 from "../../../assets/Icon/webDevelopment/icon7.svg";
import icon8 from "../../../assets/Icon/webDevelopment/icon8.svg";
import icon9 from "../../../assets/Icon/webDevelopment/icon9.svg";
import icon10 from "../../../assets/Icon/webDevelopment/icon10.svg";
import icon11 from "../../../assets/Icon/webDevelopment/icon11.svg";
import icon12 from "../../../assets/Icon/webDevelopment/icon12.svg";

import bg from "../../../assets/Icon/bg.png";
const ServiceSector = () => {
    return (
        <Box paddingTop={["40px", "40px", "60px", "80px", "80px", "80px"]} paddingBottom={["40px", "50px", "55px", "60px", "65px", "80px"]}>
            <Box width={["100%", "100%", "50%", "50%", "50%", "50%", "50%"]} margin={["0 auto"]}>
                <Text
                    textAlign={["center"]}
                    color={["#000000"]}
                    fontSize={["22px", "36px"]}
                    fontFamily={["HouschkaPro-Medium"]}
                    lineHeight={["normal"]}
                    fontWeight={["500"]}
                >
                    Our Professional Web Development <br />Services Sectors
                </Text>
            </Box>
            <Box paddingTop={["80px"]} width={["90%", "90%", "90%", "90%", "90%", "90%"]} marginLeft={[0, 0, "auto", "auto", "auto", "auto"]}>
                <Box display={["flex"]} flexDirection={["row"]}>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["25px"]} width={["55%"]} position={["relative"]} left={["0", "45px"]}>
                        <Box display={["flex"]} flexDirection={["column"]} gap={["20px"]}>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon1} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    REAL-ESTATE
                                </Text>
                            </Box>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon2} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    LOGISTICS
                                </Text>
                            </Box>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon3} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    LIFESTYLE
                                </Text>
                            </Box>
                        </Box>
                        <Box display={["flex"]} flexDirection={["column"]} gap={["20px"]} marginTop={["95px"]}>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon4} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    HEALTHCARE
                                </Text>
                            </Box>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon5} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    SOCIAL NETWORK
                                </Text>
                            </Box>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon6} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    BANKING & FINANCE
                                </Text>
                            </Box>
                        </Box>
                        <Box display={["flex"]} flexDirection={["column"]} gap={["20px"]}>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon7} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    MANUFACTURING
                                </Text>
                            </Box>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon8} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    EDUCATION
                                </Text>
                            </Box>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon9} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    DIGITAL ADVERTISING
                                </Text>
                            </Box>
                        </Box>
                        <Box display={["flex"]} flexDirection={["column"]} gap={["20px"]} marginTop={["95px"]}>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon10} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    TRAVEL & BOOKING
                                </Text>
                            </Box>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon11} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    MEDIA & ENTERTAINMENT
                                </Text>
                            </Box>
                            <Box backgroundColor={["#F3F3F3"]} borderRadius={["5px"]} padding={["25px"]} width={["169px"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["center"]}>
                                <img src={icon12} />
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "18px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                >
                                    SPORTS & FITNESS
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box backgroundImage={bg} padding={["80px"]} backgroundSize={["contain"]} backgroundRepeat={["no-repeat"]}>
                        <Text
                            color={["#ffffff"]}
                            fontSize={["16px", "36px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["500"]}
                            paddingBottom={["18px"]}
                        >
                            Industries Catered
                        </Text>
                        <Text
                            color={["#ffffff"]}
                            fontSize={["16px", "20px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                        >
                            We provide a complete range of services to turn your great ideas into profitable business solutions
                        </Text>
                        <hr
                            style={{ color: '#ffffff', marginBottom: '30px', marginTop: '30px' }}
                        />
                        <Text
                            color={["#ffffff"]}
                            fontSize={["16px", "18px"]}
                            fontFamily={["HouschkaPro-Medium"]}
                            lineHeight={["normal"]}
                            fontWeight={["400"]}
                            paddingBottom={["18px"]}
                        >
                            Don’t Worry If Your Industry Is Not Listed Here!
                        </Text>
                        <Button
                            backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}
                        >
                            Book A Free Demo
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default ServiceSector;