import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Image, Link, Flex, IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Text, useDisclosure, useBreakpointValue } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

import logo1 from "../../assets/Header/logo1.png";
import logo2 from "../../assets/Header/logo2.png";
import search from "../../assets/Header/search.svg";

const Navbar = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [heroSectionHeight, setHeroSectionHeight] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.clientHeight;
            const totalScroll = documentHeight - windowHeight;
            const scrollPercentage = (scrollTop / totalScroll) * 100;
            setScrollProgress(scrollPercentage);
            setIsSticky(scrollTop > 0);
        };

        const heroSection = document.getElementById('hero-section');
        if (heroSection) {
            setHeroSectionHeight(heroSection.clientHeight);
        }

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollThreshold = (heroSectionHeight / document.body.clientHeight) * 100;

    return (
        <Box
            width="100%"
            backgroundColor="#000000"
            padding="8px 0"
            position="sticky"
            top="0"
            zIndex="999"
        >
            <Container maxW="100%" m="0 auto">
                <Flex alignItems="center" justifyContent="space-between">
                    <Box marginRight={["30px", "40px", "50px", "60px", "70px", "75px"]}>
                        <Link href="/" style={{ textDecoration: 'none' }}>
                            <Image src={logo1} width={["91px"]} />
                        </Link>
                    </Box>
                    {isMobile ? (
                        <>
                            <IconButton
                                icon={<HamburgerIcon />}
                                variant="outline"
                                colorScheme="whiteAlpha"
                                onClick={onOpen}
                            />
                            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                                <DrawerOverlay />
                                <DrawerContent bg="#000000">
                                    <DrawerCloseButton color="white" />
                                    <DrawerHeader borderBottomWidth="1px" borderColor="#ffffff" color="white">
                                        <Image src={logo2} width={["116px"]} />
                                    </DrawerHeader>
                                    <DrawerBody>
                                        <Flex direction="column" gap="20px">
                                            {['Home', 'Service', 'Industry', 'Portfolio', 'Company', 'Resources', 'Contact Us'].map((item, index) => (
                                                <Link key={index} href="#" fontSize="18px" fontWeight="400" fontFamily="HouschkaPro-Medium" color="white">
                                                    {item}
                                                </Link>
                                            ))}
                                            {/* <Link href="#" fontSize="18px" fontWeight="500" fontFamily="HouschkaPro-Light" color="#9E9FA3">
                                                CONTACT US
                                            </Link> */}
                                        </Flex>
                                    </DrawerBody>
                                </DrawerContent>
                            </Drawer>
                        </>
                    ) : (
                        <>
                            <Flex alignItems="center" flex={1} justifyContent="flex-start" gap={["18px", "18px", "20px", "25px", "32px", "38px"]}>
                                {['Home', 'Service', 'Industry', 'Portfolio', 'Company', 'Resources'].map((item, index) => (
                                    <Link key={index} href="#" fontSize={["13px", "13px", "13px", "14px", "14px", "14px"]} fontWeight="400" fontFamily="HouschkaPro-Medium" color="white">
                                        {item}
                                    </Link>
                                ))}
                            </Flex>
                            <Flex alignItems="center" gap="28px">
                                <Link href="#" fontSize={["13px", "13px", "13px", "14px", "14px", "15px"]} fontWeight="500" fontFamily="HouschkaPro-Light" color="#9E9FA3">
                                    CONTACT US
                                </Link>
                                <Image src={search} />
                                <Image src={logo2} width={["116px"]} />
                            </Flex>
                        </>
                    )}
                </Flex>
            </Container>
            <Box height="6px" bg="#ffffff" position="absolute" bottom={0} left={0} width={`${scrollProgress}%`} zIndex={999} />
        </Box>
    );
};

export default Navbar;
