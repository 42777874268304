import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import HeroSection from "./DetailPage/HeroSection";
import TransformingBusiness from "./DetailPage/TransformingBusiness";
import Industry from "./DetailPage/Industry";
import StopSolution from "./DetailPage/StopSolution";
import CaseStudy from "./DetailPage/CaseStudy";
import DigitalSolution from "./DetailPage/DigitalSolution";
import NewsRoom from "./DetailPage/NewsRoom";
import Address from "./DetailPage/Address";

const Home = () => {
  return (
    <>
      <Box>
          <HeroSection />
      </Box>

      <Box>
        <TransformingBusiness />
      </Box>

      <Box>
        <Industry />
      </Box>

      <Box>
        <StopSolution />
      </Box>

      <Box>
        <CaseStudy />
      </Box>

      <Box>
        <DigitalSolution />
      </Box>

      <Box>
        <NewsRoom />
      </Box>

      <Box>
        <Address />
      </Box>

    </>
  );
};

export default Home;