import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import HeroSection from "./DetailPage/HeroSection";
import ServiceSector from "./DetailPage/ServiceSector";
import StopSolution from "../Home/DetailPage/StopSolution";
import Grow from "./DetailPage/Grow";
import ImageSection from "./DetailPage/ImageSection";
import Testimonial from "../AppDevelopment/DetailPage/Testimonial";
import TabSection from "./DetailPage/TabSection";

const Web = () => {
  return (
    <>
      <Box>
        <HeroSection />
      </Box>

      <Box>
        <ServiceSector />
      </Box>

      <Box>
        <TabSection />
      </Box>

      <Box>
        <StopSolution />
      </Box>

      <Box>
        <Grow />
      </Box>

      <Box>
        <ImageSection />
      </Box>

      <Box>
        <Testimonial />
      </Box>
    </>
  );
};

export default Web;