import React, { useRef, useEffect } from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import icon1 from "../../../assets/Icon/digitalMarketing/icon1.svg";
import icon2 from "../../../assets/Icon/digitalMarketing/icon2.svg";
import icon3 from "../../../assets/Icon/digitalMarketing/icon3.svg";
import icon4 from "../../../assets/Icon/digitalMarketing/icon4.svg";

import bg from "../../../assets/Icon/bg.png";
const ServiceSector = () => {
    return (
        <Box paddingTop={["80px"]} paddingBottom={["80px"]}>
            <Box width={["50%"]} margin={["0 auto"]}>
                <Text
                    textAlign={["center"]}
                    color={["#000000"]}
                    fontSize={["16px", "32px"]}
                    fontFamily={["HouschkaPro-Medium"]}
                    lineHeight={["normal"]}
                    paddingBottom={["14px"]}
                    fontWeight={["500"]}
                >
                    Full-Service Branding Agency
                </Text>
                <Text
                    textAlign={["center"]}
                    color={["#000000"]}
                    fontSize={["16px", "20px"]}
                    fontFamily={["HouschkaPro-Medium"]}
                    lineHeight={["normal"]}
                    fontWeight={["300"]}
                >
                    A team of award-winning experts focused on growing brands online
                </Text>
            </Box>
            <Box paddingTop={["40px"]} width={["90%", "90%", "90%", "80%", "80%", "80%"]} margin={["0 auto"]}>
                <Box display={["flex"]} flexDirection={["row"]}>
                    <Box display={["flex"]} flexDirection={["row"]} gap={["25px"]} width={["100%"]}>
                        <Box display={["flex"]} flexDirection={["row"]} gap={["20px"]} width={["100%"]}>
                            <Box backgroundColor={["#ffffff"]} boxShadow={["-38px 31px 17px -8px rgba(0,0,0,0.1)"]} borderRadius={["15px"]} padding={["30px"]} width={["25%"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["baseline"]}>
                                <img src={icon1} width={["65px"]} height={["65px"]}/>
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "22px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                    paddingTop={["25px"]}
                                >
                                    Brand Strategy
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Strategy
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Architecture
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Name
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Go to Market Strategy
                                </Text>
                            </Box>
                            <Box backgroundColor={["#ffffff"]} boxShadow={["-38px 31px 17px -8px rgba(0,0,0,0.1)"]} borderRadius={["15px"]} padding={["30px"]} width={["25%"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["baseline"]}>
                                <img src={icon2} width={["65px"]} height={["65px"]}/>
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "22px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                    paddingTop={["25px"]}
                                >
                                    Brand Design
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Strategy
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Architecture
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Name
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Go to Market Strategy
                                </Text>
                            </Box>
                            <Box backgroundColor={["#ffffff"]} boxShadow={["-38px 31px 17px -8px rgba(0,0,0,0.1)"]} borderRadius={["15px"]} padding={["30px"]} width={["25%"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["baseline"]}>
                                <img src={icon3} width={["65px"]} height={["65px"]}/>
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "22px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                    paddingTop={["25px"]}
                                >
                                    Custom Websites
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Strategy
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Architecture
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Name
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Go to Market Strategy
                                </Text>
                            </Box>
                            <Box backgroundColor={["#ffffff"]} boxShadow={["-38px 31px 17px -8px rgba(0,0,0,0.1)"]} borderRadius={["15px"]} padding={["30px"]} width={["25%"]} display={["flex"]} flexDirection={["column"]} gap={["20px"]} alignItems={["baseline"]}>
                                <img src={icon4} width={["65px"]} height={["65px"]}/>
                                <Text
                                    color={["#000000"]} textAlign={["center"]}
                                    fontSize={["16px", "22px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["500"]}
                                    paddingTop={["25px"]}
                                >
                                    Digital Marketing
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Strategy
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Architecture
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Brand Name
                                </Text>
                                <Text
                                    color={["#2c2c2c"]} textAlign={["center"]}
                                    fontSize={["16px", "16px"]}
                                    fontFamily={["HouschkaPro-Medium"]}
                                    lineHeight={["normal"]}
                                    fontWeight={["400"]}
                                >
                                    Go to Market Strategy
                                </Text>
                            </Box>
                        </Box>
                        <Box display={["flex"]} flexDirection={["column"]} gap={["20px"]} marginTop={["95px"]}>


                        </Box>

                    </Box>

                </Box>
            </Box>
        </Box>
    );
};
export default ServiceSector;