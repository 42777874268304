import React from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import img1 from "../../../assets/Seo/img2.png";

const Grow = () => {
    return (
        <Box>
            <Box display={["flex"]} alignItems={["center"]} flexDir={["row"]} p={['23px 0', '23px 0', '23px 0', '25px 0 53px', '113px 0 83px', '85px 207px 90px']} gap={["70px"]}>
                <Box width={["50%"]}>
                    <img src={img1} />
                </Box>
                <Box width={["50%"]}>
                    <Text
                        color={["#000000"]}
                        fontSize={["16px", "32px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["600"]}
                        paddingBottom={["16px"]}
                    >
                        Solutions for Increased Traffic & Higher Sales
                    </Text>
                    <Text
                        color={["#000000"]}
                        fontSize={["16px", "20px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["400"]}
                        paddingBottom={["16px"]}
                    >
                        What we do as a marketing agency
                    </Text>
                    <Text
                        color={["#717171"]}
                        fontSize={["16px", "18px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        fontWeight={["400"]}
                        paddingBottom={["16px"]}
                    >
                        Girafe is an online marketing agency that understands the importance of digital marketing in today’s internet business environment. We don’t just take care of the basics, we develop and execute strategic plans that include a blended mix of products targeted to improve your customer engagement, boost your organic search results, and grow your bottom line.
                    </Text>

                    <Button
                        backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Light"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}
                    >
                        Talk With Our Experts
                    </Button>
                </Box>

            </Box>
        </Box>
    );
};
export default Grow;