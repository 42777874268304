import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/font.css';
import AllRoutes from './routes/AllRoutes';
import Navbar from './components/Navbar/Navbar'; // Check the path to Navbar component
import Footer from './components/Navbar/Footer'; // Correct path to Footer component

const theme = extendTheme({
  styles: {
    global: {
      body: {
        margin: 0,
        padding: 0
      }
    }
  }
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Navbar />
        <div>
          <AllRoutes />
        </div>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;
