import React from 'react';
import { Box } from '@chakra-ui/react';
import img from "../../../assets/Seo/img1.png";

const ImageSection = () => {
    return (
        <Box paddingBottom={["50px"]} paddingTop={["50px"]} paddingLeft={["207px"]} paddingRight={["207px"]}>
            <img src={img} width={["100%"]}/>
        </Box>
    );
};
export default ImageSection;